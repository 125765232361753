import React from 'react'
import Wrapper from '../components/wrapper'
import JobSearchComponent from '../components/pageComponents/jobSearchComponent'
import cookie from 'react-cookies'
import PharmaceuticalSalesComp from '../components/pageComponents/pharmaceuticalSales'

const PharmaceuticalSalesPage = ({ location }) => {
  let user = cookie.load('user')
  let userRole = cookie.load('userRole')
  const Children = props => {
    return (
      <div>
        <PharmaceuticalSalesComp {...props} />
        <JobSearchComponent
          {...props}
          user={user}
          location={props.location}
          isSearch={true}
          role={userRole}
          openSignupModal={props.openSignupModal}
          openLoginModal={props.openLoginModal}
          sourceMethod="sales"
          preProductCategory={'Pharmaceutical'}
          searchTitle="Pharmaceutical Sales Jobs"
        />
      </div>
    )
  }

  return (
    <Wrapper location={location} title="Pharmaceutical Sales | MedReps.com">
      <Children />
    </Wrapper>
  )
}
export default PharmaceuticalSalesPage
