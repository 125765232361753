import React from 'react'
import {
  HeroLeftSide,
  SignUpForm,
  ReviewSection,
  MemberShipSection,
} from '../additional'

const LIST_FAST_FACTS = [
  '50% of members received a job offer',
  '85% had an interview',
  '90% would recommend MedReps',
]

const PharmaceuticalSalesComp = ({ user }) => {
  return (
    <div>
      {!user && (
        <div className="container mx-auto mt-10">
          <div className="max-w-[960px] mx-auto flex gap-[55px] p-5 flex-col md:flex-row">
            <HeroLeftSide
              title="Looking for pharmaceutical sales jobs?"
              desc="MedReps connects talented individuals to the latest job openings and career opportunities with leading pharmaceutical companies."
              listTitle="Fast facts:"
              listOption={LIST_FAST_FACTS}
            />
            <SignUpForm />
          </div>
        </div>
      )}
      <MemberShipSection />
      <ReviewSection />
    </div>
  )
}

export default PharmaceuticalSalesComp
